<div class="news-container">
  <div class="feature-news">
    <img alt="news-banner" i18n-alt loading="lazy" src="assets/icons/news-banner.svg" />
    <p>Футбольна асоціація Англії засудила расистські висловлювання вболівальників</p>
    <div class="timeline flex-row flex-wrap start flex-start gap-16">
      <img alt="pnt" i18n-alt loading="lazy" src="assets/icons/pnt.svg" />
      <p>1 week</p>
    </div>
    <div class="custom-hr"></div>

    <div class="news-list">
      <div class="flex-row flex-wrap start flex-start gap-16">
        <div>
          <p>У Лондоні після фіналу Євро-2020 відбулися зіткнення між фанатами</p>
          <div class="timeline flex-row flex-wrap start flex-start gap-16">
            <img alt="pnt" i18n-alt loading="lazy" src="assets/icons/pnt.svg" />
            <p>1 day</p>
          </div>
        </div>
        <img alt="news1" i18n-alt src="assets/icons/news1.svg" />
      </div>
    </div>
  </div>
</div>