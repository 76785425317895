import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FieldModalComponent } from 'src/app/modals/field-modal/field-modal.component';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { getMatDialogConfig } from 'src/app/utils/material.utils';
import { EventsService } from '../events/events.service';
import { FieldType } from 'src/app/services/hobbyts.service';
import { NgFor } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'hb-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
  standalone: true,
  imports: [NgFor, MatIconModule]
})
export class FieldComponent implements OnInit {
  public fields: FieldType[];

  constructor(
    private eventsService: EventsService,
    public dialog: MatDialog,
    private lazyDialog: LazyDialogService,
    private storageService: StorageService
  ) {}

  public ngOnInit(): void {
    const currentCity = this.storageService.getValue('store-city') || '';
    this.eventsService.getAllFields(currentCity).subscribe((res) => {
      if (res) {
        this.fields = res.getAllFields;
      }
    });
  }

  public async openFieldPage(field: FieldType): Promise<void> {
    const config = getMatDialogConfig(this.dialog, FieldModalComponent, field);
    await this.lazyDialog.openLazyLoadedDialog<FieldModalComponent>(DialogNames.FIELD, config).then();
  }
}
