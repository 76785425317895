import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FieldComponent } from '../fields/fields.component';
import { NewsComponent } from '../news/news.component';
import { LinkComponent } from '../profile/info/links/link.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'hb-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatTabsModule, LinkComponent, NewsComponent, FieldComponent],
  host: { class: 'background-host-page' }
})
export class CityComponent {
  constructor(private router: Router) {}

  navigateToEventsPage() {
    this.router.navigate(['home']);
  }
}
