<div class="field-list" *ngFor="let field of fields">
  <div class="field-card mat-mdc-card" (click)="openFieldPage(field)">
    <div class="main-content">
      <div class="flex-row gap-16">
        <img alt="news1" i18n-alt loading="lazy" class="first-icon" src="assets/icons/news1.svg" />
        <div class="location">
          <p class="title">{{ field.title }}</p>
          <div class="flex-row align-center">
            <mat-icon svgIcon="location-blue"></mat-icon>
            <p class="location-text">{{ field.city }}</p>
          </div>
        </div>
      </div>

      <p class="field-description info-text">
        {{ field.description }}
      </p>
      <div class="custom-hr"></div>

      <div class="bottom-menu">
        <div class="flex-row align-center gap-8">
          <mat-icon [svgIcon]="field.isIndoor ? 'home' : 'tree'"></mat-icon>
          <div class="vertical-line"></div>
          <mat-icon svgIcon="football"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>