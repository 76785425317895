<div class="city-container">
  <div class="top-bar flex-row">
    <div class="justify-center flex-1">
      <mat-icon class="arrow-back cursor-pointer" (click)="navigateToEventsPage()">arrow_back</mat-icon>
    </div>
    <span class="page-label justify-center flex-1" i18n>City</span>
    <div class="flex-1"></div>
  </div>

  <div class="city-bg">
    <div class="location flex-row start align-center">
      <div class="ml-16">
        <mat-icon svgIcon="location-blue"></mat-icon>
        <p>Odessa, Ukraine</p>
      </div>
    </div>
  </div>

  <div class="city-form">
    <p class="first-text">
      Letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop
      publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
      ipsum' will uncover main their infancy.
    </p>

    <div class="map-bg">
      <div class="map-location-icon">
        <mat-icon svgIcon="location-blue"></mat-icon>
      </div>
    </div>

    <div class="tabs">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Info" i18n-label>
          <hb-info-links></hb-info-links>
        </mat-tab>
        <mat-tab label="News" i18n-label>
          <hb-news></hb-news>
        </mat-tab>
        <mat-tab label="Fields" i18n-label>
          <hb-fields></hb-fields>
        </mat-tab>
        <mat-tab label="Events" i18n-label>
          <p class="mat-option-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s
          </p>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>